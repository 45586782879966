
// export const base_url = 'http://127.0.0.1:8000/api' // api base url
// export const img_url = 'http://127.0.0.1:8000/storage' // api photo url
// export const domain_url = 'http://localhost:3000' // domain url


export const base_url = 'https://api.smartcafe.az/api' // api base url
export const img_url = 'https://api.smartcafe.az/storage' // api photo url
export const domain_url = 'https://smartcafe.az' // domain url




